<template>
  <div style="text-align: right">
    <v-card>
      <v-card-title>
        کاربران آنلاین
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details class="searchStyle"></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="onlineUserLists" :search="search">
        <template v-slot:item.operations="{ item }">
          <div style="display:flex;justify-content:space-around">
            <router-link :to="'/documentUserManage/' + item.username">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" v-on="on">
                    <v-icon large>
                      mdi-folder-open
                    </v-icon>
                  </v-btn>

                </template>
                <span style="color:white">پرونده</span>
              </v-tooltip>
            </router-link>
            <router-link :to="'/turnOverUser/' + item.username">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                    <v-icon large>
                      mdi-clipboard-text-clock-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span style="color:white">سوابق مالی</span>
              </v-tooltip>
            </router-link>
            <router-link to="/securityUser">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                    <v-icon large>
                      mdi-shield-account-outline
                    </v-icon>
                  </v-btn>

                </template>
                <span style="color:white">امنیت</span>
              </v-tooltip>
            </router-link>
            <router-link to="/userMessages">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                    <v-icon large>
                      mdi-message-settings-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span style="color:white">پیام ها</span>
              </v-tooltip>
            </router-link>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axiosApi from "@/axios";

export default {
  name: "FilterUsersList",
  data() {
    return {
      onlineUserLists: [],
      headers: [
        {text: 'نام ', value: 'firstName'},
        {text: 'فامیل ', value: 'lastName'},
        {text: 'تلفن همراه', value: 'username'},
        {text: 'عملیات', value: 'operations'},
      ],
      search : ''
    }
  }
  ,
  mounted() {
    this.onlineUserList()
  },
  methods: {
    onlineUserList() {
      axiosApi().post('/api/Manage/v1/Report/GetOnlineUsersList')
          .then(({data}) => {
            if (this.$root.filterOnlineUsers === 'admin')
              this.onlineUserLists = data.data.onlineManagers
            else
              this.onlineUserLists = data.data.onlineUsers
          })
    }
  }
}
</script>

<style scoped>

</style>
